import { Injectable } from '@angular/core';

import { WebSocketSubject } from 'rxjs/webSocket';
import { Subject } from 'rxjs';
import { DateTime } from 'luxon';

import { DatesService } from '../../../core/services/dates/dates.service';
import { WSService } from '../ws/ws.service';
import {
  Invitation,
  InvitationAction,
  InvitationProcess, InvitationSender,
  SendInvitationData
} from '../../models/invitation/invitation.model';
import { Room } from '../../models/room/room.model';
import { User } from '../../models/user/user.model';


export interface TimeSlot {
  start: string;
  end: string;
}

@Injectable({
  providedIn: 'root'
})
export class InvitationService {
  public socket$: WebSocketSubject<any>;

  inviteAction = new Subject<SendInvitationData>();
  inviteActionClick$ = this.inviteAction.asObservable();

  invitationProcess: InvitationProcess = {
    invitation: null,
    action: null,
    room: null,
    loading: false,
    visible: false
  };

  constructor(
    private ds: DatesService,
    private wsService: WSService
  ) { }

  inviteClick(data: any): void {
    if (data) {
      this.inviteAction.next(data);
    }
  }

  /**
   * set property bag for invitation
   * @param data InvitationProcess
   */
  setInvitationProcess(data: InvitationProcess): void {
    this.invitationProcess = Object.assign({}, this.invitationProcess, data);
  }

  /**
   * get property bag for invitation
   */
  getInvitationProcess(): InvitationProcess {
    return this.invitationProcess;
  }

  /**
   * establish web socket connection for specified room
   * @param roomId number - id of room for connection
   */
  connectToInvitations(roomId: number, sender: InvitationSender): void {
    this.disconnectInvitation();

    this.socket$ = this.wsService.connect(`calendar/${roomId}`, () => {
      this.getInvitations(sender);
    });
  }

  disconnectInvitation(): void {
    if (this.socket$) {
      this.socket$.complete();
      this.socket$ = null;
    }
  }

  getInvitationSocket(): WebSocketSubject<any> {
    return this.socket$;
  }

  getInvitations(sender: InvitationSender): void {
    const timezone = this.ds.getClientTimezone();

    this.wsService.sendConnectMessage(this.socket$, {
      sender,
      timezone,
    });
  }

  /**
   * send invitation
   * @param invitation Invitation
   * @param action 'create' | 'rebook' | 'update'
   * @param user User
   * @param room Room - chat room for communication
   */
  sendInvitation(invitation: Invitation, action: InvitationAction, user: User, room: Room): void {
    const timezone = this.ds.getClientTimezone();

    this.wsService.sendMessage(this.socket$, {
      sender: 'employee',
      action,
      timezone,
      message: Object.assign({}, invitation, {
        room_id: room.id,
        employee_id: user.id,
        applicant_id: room.applicant.id,
        application_id: room.application.id
      })
    });
  }

  /**
   * accept invitation (change status to 'saved')
   * @param data any
   */
  acceptInvitation(data: any): void {
    const timezone = this.ds.getClientTimezone();

    this.wsService.sendMessage(this.socket$, {
      sender: 'applicant',
      timezone,
      action: data.action,
      message: {
        invitation_id: data.id,
        date: data.date.day.date,
        time: data.date.time
      }
    });
  }

  /**
   * reject invitation (change status to 'rejected')
   * @param data any
   */
  rejectInvitation(data: any): void {
    const timezone = this.ds.getClientTimezone();

    this.wsService.sendMessage(this.socket$, {
      sender: data.sender,
      timezone,
      action: data.sender === 'employee' ? InvitationAction.CANCEL : InvitationAction.REJECT,
      message: {
        invitation_id: data.invitationId
      }
    });
  }

  /**
   * Splits available time into slots depending on duration and pause
   * @param availableTime DateTime[] - array of time which should be split into slots
   * @param duration number - duration of one slot in minutes
   * @param pause number - time in minutes after each interview
   */
  getTimeSlots(availableTime: DateTime[] = [], duration: number, pause: number): DateTime[] {
    let slots = [];
    const now = this.ds.nowISO();

    availableTime.forEach(el => {
      slots = [...slots, ...this.ds.splitInterval(el.start, el.end, duration, pause)].filter(t => {
        return t > now;
      });
    });

    return slots;
  }

  /**
   * check if array of time slots has overlaps
   * @param timeSlots - string[] array of time to check overlap in
   * @param duration - number - duration in minutes of one slot
   * @param pause - number - pause in minutes after each slot
   */
  hasTimeIntersections(timeSlots: string[], duration: number, pause: number): boolean {
    const sortedTimeSlots = timeSlots.sort((a, b) => a.localeCompare(b));

    return !!sortedTimeSlots.find((el, i) => {
      const currentEndTime = this.ds.fromISO(el).plus({minutes: duration + pause});
      const nextStartTime = this.ds.fromISO(sortedTimeSlots[i + 1]);

      return currentEndTime > nextStartTime;
    });
  }
}
