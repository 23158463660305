import { Room } from '../room/room.model';

export interface InvitationDate {
  date: string;
  timeslots: string[];
  timeOptions?: string;
  isSelectedAllTime?: boolean;
}

export interface Attender {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
}

export enum LocationType {
  PHYSICAL = 'physical',
  ZOOM = 'zoom',
  TEAMS = 'teams'
}

export enum InvitationStatus {
  PENDING = 'pending',
  SAVED = 'saved',
  CANCELED = 'canceled',
  REJECTED = 'rejected',
  EDIT = 'editing',
}

export enum InvitationAction {
  SAVE = 'save',
  CREATE = 'create',
  REBOOK = 'rebook',
  UPDATE = 'update',
  ACCEPT = 'accept',
  CANCEL = 'cancel',
  EDIT = 'edit',
  REJECT = 'reject'
}

export interface GoogleLocation {
  description: string;
}

export interface Address {
  type: LocationType;
  description: string | GoogleLocation;
}

export interface SendInvitationData {
  data: Invitation;
  message?: string;
  attenders: Attender[];
}

export interface Invitation {
  id?: number;
  active: boolean;
  event_id?: string;
  summary: string;
  description: string;
  attenders: Attender[];
  organiser?: Attender;
  location: Address;
  duration: number;
  pause: number;
  possible_days: InvitationDate[];
  status?: InvitationStatus;
  date?: string;
  start?: string;
  end?: string;
  created_at?: string;
  updated_at?: string;
  applicant?: Attender;
  employee?: Attender;
  application_id?: number;
}

/**
 * property bag for manipulation with invitation
 * identifies invitation processed
 * action performed over invitation
 * and loading status for a spinner
 */
export interface InvitationProcess {
  invitation?: Invitation; // invitation processed
  action?: InvitationAction;
  room?: Room; // room in which invitation is processed
  loading?: boolean;
  visible?: boolean; // show/hide block with invitation form
}

export enum InvitationSender {
  APPLICANT = 'applicant',
  EMPLOYEE = 'employee'
}

export interface CalendarValidationData {
  connected: boolean;
  detail: string;
  pending: boolean; // if there are pending invitations
}
