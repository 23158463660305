import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { WebSocketSubject } from 'rxjs/webSocket';
import { Observable } from 'rxjs';

import { Room } from '../../../shared/models/room/room.model';
import { ChatWSResponse, Message, WSMessage, WSStatus } from '../../../shared/models/message/message.model';
import { WSService } from '../../../shared/services/ws/ws.service';
import { User } from '../../../shared/models/user/user.model';
import { Company } from '../../../shared/models/company/company.model';
import { InvitationService } from '../../../shared/services/invitation/invitation.service';

import { environment } from '../../../../environments/environment';
import { WebHelperService } from '../../services/web-helper/web-helper.service';
import { ModalActions } from '../../actions/modal/modal.action';
import { ToastComponent } from '../../../ui/toast/toast.component';
import { IAppState } from '../../ngrx';
import { Store } from '@ngrx/store';
import { DatesService } from '../../services/dates/dates.service';
import { Invitation } from '../../../shared/models/invitation/invitation.model';




const apiUrl = environment.apiUrl;

@Injectable()
export class ChatService {
    constructor(
        private store: Store<IAppState>,
        private http: HttpClient,
        private wsService: WSService,
        private invitationService: InvitationService,
        private helper: WebHelperService,
        private datesService: DatesService
    ) {
    }

    private chatUrl = apiUrl + 'chat/rooms/';
    private groupChatUrl = apiUrl + 'chat/messages/';

    private chatSocket$: WebSocketSubject<any>;
    private roomsSocket$: WebSocketSubject<any>;

    public postGroupMessage(groupMessage): Observable<any> {
      const body = JSON.stringify(groupMessage);

      return this.http.post<any>(this.groupChatUrl, body);
    }

    public connectToChat(roomId: number): void {
      this.disconnectChat();

      const timezone = this.datesService.getClientTimezone();

      this.chatSocket$ = this.wsService.connect(`chat/${roomId}`, () => {
        this.wsService.sendConnectMessage(this.chatSocket$, {
          timezone
        });
      });
    }

    sendMessage(text: string, mergedMessages: any[], room: Room, user: User, company?: Company): any[] {
      if (!mergedMessages) {
        mergedMessages = [];
      }

      if (text.trim().length === 0) {
        return mergedMessages;
      }

      const tempId = this.helper.generateUniqueId();


      const optimisticMessage = {
        date: new Date().toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }),

        items: [{
          company_id: company ? company.id : null,
          author_id: user.id,
          text: text.replace('{{applicant_name}}', room.applicant.first_name),
          room_id: room.id,
          temp_id: tempId
        }]
      };

      mergedMessages = this.mergeMessages(mergedMessages, [optimisticMessage]);

      this.wsService.sendMessage(this.chatSocket$, {
        action: 'send',
        data: {
          ...(company && { company_id: company.id }),
          author_id: user.id,
          text,
          room_id: room.id,
          temp_id: tempId
        }
      });

      return mergedMessages;
    }

    handleWSMessage(params: {
      wsMessage: WSMessage,
      feed: ChatWSResponse[],
      failedAction: any,
      socket: WebSocketSubject<ChatWSResponse[]>
    }): ChatWSResponse[] {
      const { wsMessage, feed, failedAction, socket } = params;

      let mergedMessages: ChatWSResponse[] = feed;

      switch (wsMessage.status) {
        case WSStatus.RETRIEVED: {
          mergedMessages = this.mergeMessages(feed, wsMessage.data);

          if (failedAction) {
            this.wsService.sendMessage(socket, params.failedAction);
          }

          break;
        }

        case WSStatus.CREATED: {
          // convert to the same format as the existing messages
          const date = new Date(wsMessage.data.updated_at);

          const formattedDate: string = date.toLocaleDateString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          });

          const formattedWSMessage = {
            date: formattedDate,
            items: [wsMessage.data]
          };

          // Check if there is an existing group of messages with the same date
          const existingDateGroup = !!feed.find(m => m.date === formattedWSMessage.date);

          // If there is, update the existing group
          if (existingDateGroup) {
            mergedMessages = feed.map(m => {
              if (m.date === formattedWSMessage.date) {
                m.items = this.updateOptimisticMessage(m.items, wsMessage.data);
              }
              return m;
            });
          } else {
            // If no message with the same date exists, add the new formatted message
            mergedMessages = this.mergeMessages(feed, [formattedWSMessage]);
          }

          break;
        }

        case WSStatus.UPDATED: {
          const messagesToUpdate = Array.isArray(wsMessage.data) ? wsMessage.data : [wsMessage.data];
          mergedMessages = this.updateMessages(feed, messagesToUpdate);
          break;
        }

        case WSStatus.CONFIRMED:
          this.invitationService.setInvitationProcess({
            invitation: null,
            action: null,
            loading: false,
            visible: false
          });
          break;

        case WSStatus.FAILED:
          this.showError(wsMessage);

          this.invitationService.setInvitationProcess({
            loading: false,
          });

          break;
      }

      return mergedMessages;
    }

    showError(message: any): void {
      const title = message && typeof message.data === 'string' ? message.data : 'ERRORS.SOMETHING_WRONG';

      this.store.dispatch(
        new ModalActions.OpenAction({
          cmpType: ToastComponent,
          props: {
            view: 'error',
            title
          },
        }));
    }

    disconnectChat(): void {
      if (this.chatSocket$) {
        this.chatSocket$.complete();
        this.chatSocket$ = null;
      }
    }

    getChatSocket(): WebSocketSubject<any> {
      return this.chatSocket$;
    }

    getRoomsSocket(): WebSocketSubject<any> {
      return this.roomsSocket$;
    }

    getRooms(userId: number, data?: {
      company_id?: number;
      job_id?: number;
      search_query?: string;
    }): void {
      this.roomsSocket$ = this.wsService.connect(`chat/${userId}/rooms`, () => {
        this.wsService.sendConnectMessage(this.roomsSocket$, {
          data
        });
      });
    }

  filterRooms(userId: number, data?: {
    page?: number;
    page_size?: number;
    company_id?: number;
    job_id?: number;
    search_query?: string;
  }): void {
    if (!this.roomsSocket$) {
      return;
    }

    this.wsService.sendMessage(this.roomsSocket$, {
      action: 'get',
      data
    });
  }

    public getRoom(roomId: number): Observable<Room> {
        return this.http.get<Room>(this.chatUrl + roomId + '/');
    }

  updateMessages(feed: ChatWSResponse[], updatedMessages: Message[] | Invitation[]): ChatWSResponse[] {
    const updatedMessagesMap = new Map<number, Message | Invitation>();

    // Create a map of updated messages for quick lookup
    updatedMessages.forEach(message => {
      updatedMessagesMap.set(message.id, message);
    });

    // Iterate through the feed and update messages
    return feed.map(group => {
      const updatedItems = group.items.map(item => {
        if (updatedMessagesMap.has(item.id)) {
          return updatedMessagesMap.get(item.id);
        }
        return item;
      });

      return {
        ...group,
        items: updatedItems
      };
    });
  }

    /**
     * Update optimistic messages with the real one
     * @param allMessages - existing messages
     * @param messageToUpdate - new message or array of messages
     */
    updateOptimisticMessage(
      allMessages: Partial<Message>[] | Partial<Invitation>[],
      messageToUpdate: any): any {

      const isItemExist = allMessages.some((m) => m.temp_id && m.temp_id === messageToUpdate.temp_id || m.id === messageToUpdate.id);

      if (!isItemExist) {
        return [...allMessages, messageToUpdate];
      }

      return allMessages.map((m) => {
        if (m.temp_id && m.temp_id === messageToUpdate.temp_id || m.id === messageToUpdate.id) {
          m = messageToUpdate;
        }

        return m;
      });
    }


  /**
   * add new rooms to the existing ones excluding duplicates
   * @param allMessages - existing messages
   * @param message - new message
   */
    mergeRooms(allMessages: any[], message: any): any[] {
      const uniqueIds = new Set(allMessages.map(msg => msg.id));
      const newMessages = Array.isArray(message) ? message : [message];
      const uniqueNewMessages = newMessages.filter(msg => !uniqueIds.has(msg.id));

      return [...allMessages, ...uniqueNewMessages];
    }

    /*
      * Merge messages grouped by date
     */
    mergeMessages(allMessages: ChatWSResponse[], newMessage: ChatWSResponse[]): any {
      const mergedMessages = [...allMessages, ...newMessage];

      // Use a Map to store messages by date for efficient merging
      const groupedByDate = new Map<string, { date: string; items: any[] }>();

      mergedMessages.forEach(message => {
        const { date, items } = message;

        const itemsArray = Array.isArray(items) ? items : [items];

        // If the date is already in the map, merge the items
        if (groupedByDate.has(date)) {
          const existingItems = groupedByDate.get(date)!.items;
          const uniqueItems = new Set(existingItems.map(item => item.id));

          itemsArray.forEach(item => {
            if (!uniqueItems.has(item.id)) {
              existingItems.push(item);
              uniqueItems.add(item.id);
            }
          });
        } else {
          // Otherwise, add a new entry for this date
          groupedByDate.set(date, { date, items: [...itemsArray] });
        }
      });

      // Convert the map back into an array for the final output
      return Array.from(groupedByDate.values());
    }

    sortRooms(rooms: any[], allNotifications): any[] {
      return rooms.sort((roomA, roomB) => {
        // Sort by updated_at in descending order
        const updatedAtComparison = new Date(roomB.updated_at).getTime() - new Date(roomA.updated_at).getTime();

        // If updated_at is the same, sort by notifications count in descending order
        const notificationsComparison = allNotifications[roomB.id] - allNotifications[roomA.id];

        // Combine both comparisons
        if (updatedAtComparison === 0) {
          return notificationsComparison;
        } else {
          return updatedAtComparison;
        }
      });
    }
}
